:root {
  /* Colors */
  --color-black: #212529;
  --color-gray-dark: #757575;
  --color-gray: #D8D8D8;
  --color-gray-light: #ececec;
  --color-gray-extra-light: #f6f6f6;
  --color-white: #fff;

  --color-primary-red: 41;  
  --color-primary-green: 171;
  --color-primary-blue: 226;

  --color-primary: rgb(
    var(--color-primary-red),
    var(--color-primary-green),
    var(--color-primary-blue)
  );
  --color-primary-darkened: #1998D6;
  --color-primary-lightened: #6FC0E2;
  --color-secondary: #939598;
  --color-light: #DDEDFF;

  --brand-danger: #939598;
  --brand-disabled: var(--color-gray-light);
  --brand-success: #008547;
  --brand-warning: #FFA400;

  /* Typography */
  --font-family-base: 'Lato', sans-serif;
  --font-family-headings: 'Lato', sans-serif;

  /* Nav */
  --nav-height: 3.5rem;
  --nav-height--lg: 5rem;

  /* Spinner */
  --spinner-color: var(--color-primary);
  --spinner-color-ring: rgba(
    var(--color-primary-red),
    var(--color-primary-green),
    var(--color-primary-blue),
    .3
  );

  /* Links */
  --link-color: #0156AC;
  --link-color--hover: #175DAF;

/* Global Alert */
  --global-alert-height: 12rem;
  --share-banner-height: 5rem;
  
  /* Lead Bar */
  --lead-bar-height: 8rem;

  /* CSC: Header */
  --header-background: var(--color-primary);
  --header-color: var(--color-light);

  /* CSC: CardList */
  --card-list-width: 540px;

  /* MAPTV: Header */
  --maptv-header-height: 64px; /* Set fixed height so we can calc step offsets */
  
    --color-election-center-bg: #302f2f;
}

@media (min-width: 576px) {
  :root {
    --maptv-header-height: 112px;
  }
}

.LocalNav__BtnMaptv {
    background-color: #008547 !important;
    color: #ffffff !important;
  	border-color: #008547 !important;
}
.WelcomePanel {
  background-color: #eee !important;
}

    /* Share Scene */
    :root {
      --share-image-background: #326272;
      --share-image-text: #e39674;
      --share-image-label-text: #ffffff;
    }

a[aria-label="government link"] {
  display: none !important;
  position: unset !important;
}